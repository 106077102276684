import $ from 'jquery';

$('[data-scroll-to-section]').on('click', event => {
  event.preventDefault();

  const section = event
    .target
    .closest('[data-scroll-to-section]')
    .dataset
    .scrollToSection;

  document
    .getElementById(section)
    .scrollIntoView({ behavior: 'smooth', block: 'start' });
});
